.newPartyPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.newPartyPage__container {
    display: flex;
    flex-direction: column;
}
