body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input, textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.defaultInputWidth {
    width: 200px;
}

.centerText {
    text-align: center;
}

.errorMessage {
    background-color:#C11;
    color: #FFF;
    padding: 0.25em;
    border-radius: 6px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.m-0 {
    margin: 0;
}

.mt-1 {
    margin-top: 0.5em;
}

.mt-2 {
    margin-top: 1em;
}

.mt-4 {
    margin-top: 2em;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 0.5em;
}

.mb-2 {
    margin-bottom: 1em;
}

.mb-4 {
    margin-bottom: 2em;
}

.textInput {
    padding: 1em;
    border-radius: 6px;
}

.buttonInput {
    padding: 1em;
    border-radius: 6px;
    color: #282c34;
    background-color: #FFF;
}

.buttonInput:hover:enabled {
    color: #FFF;
    background-color: #282c34;
    border-color: #000;
    cursor: pointer;
}

.buttonInput:disabled {
    background-color: #AAA;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textShadow {
    text-shadow: 0 0 0.25em #282c34;
}

.dropShadow {
    box-shadow: 0 0 0.25em #282c34;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}