.rejoinPartyPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.rejoinPartyPage__center {
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rejoinPartyPage__list {
    width: 50%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.rejoinPartyPage__player {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: 0.5em;
    background-color: #00a7cc;
}

.rejoinPartyPage__player:hover {
    cursor: pointer;
}

.rejoinPartyPage__playerIcon {
    height: 2em;
    min-width: 2em;
    border-width: 0.5em;
    border-style: solid;
    border-radius: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
    color: #282c34;
    background-color: white;
}