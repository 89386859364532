.verifyRejoinPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verifyRejoinPage__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}