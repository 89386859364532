.teamsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamsList__team {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    min-width: 250px;
    padding: 0.5em;
    margin-bottom: 0.5em;
    color: #111;
    border-radius: 4px;
}

.teamsList__team:hover {
    cursor: pointer;
}

.teamsList__team--active {
    border: 0.25em solid #FFF;
    outline: 0.25em solid #000;
}

.teamsList__teamRightColumn {
    text-align: center;
    min-width: 50px;
}