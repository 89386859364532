.gamesPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.gamesPage__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gamesPage__game {
    width: 80%;
    padding: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    border-radius: 0.5em;
}

.gamesPage__game:hover {
    cursor: pointer;
    background-color: #fff;
    color: #000;
}

.gamesPage__bullet {
    height: 2em;
    width: 0.5em;
    border-radius: 0.5em;
}

.gamesPage__gameSpacer {
    height: 2px;
    width: 80%;
    background-color: #777;
}

.gamesPage__end {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

@media (min-width: 768px) {
    .gamesPage__game {
        width: 60%;
    }

    .gamesPage__gameSpacer {
        width: 60%;
    }
}