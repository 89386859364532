.newPlayerPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.newPlayerPage__center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newPlayerPage__container--space {
    padding: 1em;
    text-align: center;
}