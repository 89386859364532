.newPlayerForm {
    display: flex;
    flex-direction: column;
}

.newPlayerForm__color {
    width: calc(100% - 0.5em);
    height: 45px;
    margin-top: 0.25em;
    border: 0.25em solid #000;
    border-radius: 6px;
}

.newPlayerForm__color:hover {
    cursor: pointer;
}

.newPlayerForm__colorContainer {
    position: absolute;
    z-Index: 2;
}

.newPlayerForm__colorCover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
