.playerScores {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.playerScores__score {
    width: 100%;
    display: flex;
}

.playerScores__ranking {
    min-height: 2em;
    min-width: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playerScores__player {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: 0.5em;
    background-color: #00a7cc;
}

.playerScores__playerInfo {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.playerScores__playerIcon {
    height: 2em;
    min-width: 2em;
    border-width: 0.5em;
    border-style: solid;
    border-radius: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
    color: #282c34;
    background-color: white;
}

.playerScores__scoreButtons {
    display: flex;
    align-items: center;
}

.playerScores__scoreButton {
    min-height: 2em;
    min-width: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding: 0;
    border: 0.25em solid #FFF;
    border-radius: 2em;
    font-size: 1em;
    font-weight: 900;
    text-align: center;
    color: #FFF;
    background-color: #00a7cc;
}

.playerScores__scoreButton:hover {
    cursor: pointer;
    border-color: #333;
    color: #333;
}