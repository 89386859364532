.partySettings {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partySettings__banner {
    width: 100%;
    min-height: 1.5em;
    padding: 1em;
    margin-bottom: 1em;
    background-color: #C11;
    border-radius: 0.5em;
    text-align: center;
    vertical-align: middle;
}

.partySettings__banner > * {
    padding: 0;
    margin: 0;
}

.partySettings__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
}

.partySettings__row > span {
    line-height: 34px;
}