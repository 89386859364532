.newPartySettingsPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.newPartySettingsPage__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
