.errorsPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorsPage__container {
    display: flex;
    flex-direction: column;
}

.errorsPage__errorsPanel {
    border: 1px solid #FFF;
    border-radius: 0.5em;
    padding: 1em;
}