.scoreboardPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.scoreboardPage__container {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scoreboardPage__header {
    width: 100%;
    text-align: center;
}

.scoreboardPage__scores {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.scoreboardPage__teamScores {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.scoreboardPage__playerScores {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.scoreboardPage__player {
    display: flex;
}

.scoreboardPage__ranking {
    min-height: 2em;
    min-width: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scoreboardPage__playerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: 0.5em;
    background-color: #00a7cc;
}

.scoreboardPage__playerInfo {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.scoreboardPage__teamInfo {
    height: 3em;
    display: flex;
    align-items: center;
    margin-left: 0.5em;
}

.scoreboardPage__playerIcon {
    height: 2em;
    min-width: 2em;
    border-width: 0.5em;
    border-style: solid;
    border-radius: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
    color: #282c34;
    background-color: white;
}

.scoreboardPage__score {
    display: flex;
    align-items: center;
    margin-right: 0.5em;
}

@media (min-width: 768px) {
    .scoreboardPage__scores {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
}