.addEditGamePage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addEditGamePage__form {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
}
