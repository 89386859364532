.gameInfoPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.gameInfoPage__container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gameInfoPage__instructions {
    width: 100%;
}

.gameInfoPage__spacer {
    height: 2px;
    width: 100%;
    background-color: #777;
}

.gameInfoPage__buttons {
    width: 80%;
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
}

.gameInfoPage__deleteButton {
    background-color: #ff9999;
}

.gameInfoPage__deleteButton:hover:enabled {
    background-color: #dd0000;
}