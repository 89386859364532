.deleteGamePage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deleteGamePage__deleteButton {
    margin: 2em;
    background-color: #ff9999;
}

.deleteGamePage__deleteButton:hover:enabled {
    background-color: #dd0000;
}